import React from "react";
import { graphql } from "gatsby";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import {
  getDataCTAContato,
  getDataFooter,
  getDataMenu,
  getDataSearchMenu,
  getDataSectionQualityWarranty,
  getDataSpecialties
} from "_utils/assemblePagesData";
import type { InternalSpecialtyPageProps } from "_types/IEspecialidadeInternaPage";
import { InternalSpecialty } from "presentation/screens";
import { WEBSITE_URL } from "_utils/constants";
import SEO from "presentation/Seo";

function InternalSpecialtyPage({ data }: InternalSpecialtyPageProps) {
  const specialties = getDataSpecialties({
    specialties: data?.specialties
  });

  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });
  const footerData = getDataFooter({ data: data?.menu?.data?.body });

  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });

  const ctaContato = getDataCTAContato({ ctaContato: data?.ctaContato });
  const qualityWarranty = getDataSectionQualityWarranty({
    sectionQuality: data?.sectionQuality
  });

  const sectionHeader = {
    icon: data?.prismicEspecialidade?.data?.icone_da_especialidade?.url,
    name: data?.prismicEspecialidade?.data?.nome_da_especialidade?.richText,
    description: data?.prismicEspecialidade?.data?.descricao_curta?.richText,
    viewAll: data?.prismicPaginaEspecialidades?.uid,
    retirectToSearchExam: data?.prismicBuscarExames?.uid
  };

  const dataSEO = {
    canonicalUrl: `${WEBSITE_URL}${data?.prismicEspecialidade?.data?.canonical_url?.text}`,
    noFollow: data?.prismicEspecialidade?.data?.no_follow,
    noIndex: data?.prismicEspecialidade?.data?.no_index,
    openGraph: data?.prismicEspecialidade?.data?.open_graph[0],
    telefone1: data?.contact?.data?.telefone_1?.text,
    email: data?.contact?.data?.email?.text,
    imagesPage: [],
    metaTags: [
      {
        property: "application-name",
        content: "GatsbySeo"
      },
      {
        property: "og:title",
        content:
          data?.prismicEspecialidade?.data?.open_graph[0]?.og_title?.text ??
          "sem conteúdo"
      },
      {
        property: "og:url",
        content: `${WEBSITE_URL}${data?.prismicEspecialidade?.uid}`
      },
      {
        property: "og:image",
        content: data?.prismicEspecialidade?.data?.open_graph[0]?.og_image?.url
      },
      {
        property: "og:type",
        content: "website"
      }
    ]
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        footerData={footerData}
        contact={data?.contact}
      >
        <InternalSpecialty
          sectionHeader={sectionHeader}
          ctaContato={ctaContato}
          sectionQualityWarranty={qualityWarranty}
          sliceZone={data?.prismicEspecialidade?.data?.body}
        />
      </TemplatePage>
    </ThemeProvider>
  );
}
// query MyQuery($id: String) {
// prismicEspecialidade(uid: { eq: $id }) { no ___graphql

export const query = graphql`
  query getDataInternalSpecialty($id: String) {
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    contact: prismicContato {
      ...Contact
    }
    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          nome_da_especialidade {
            richText
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }
    searchExams: prismicBuscarExames {
      ...BuscarExamesFragment
    }
    ctaContato: prismicCtaContato {
      ...CTAContatoFragment
    }
    sectionQuality: prismicQualidade {
      ...QualityFragment
    }
    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }
    prismicEspecialidade(id: { eq: $id }) {
      uid
      data {
        no_follow
        no_index
        open_graph {
          og_url {
            text
          }
          og_title {
            text
          }
          og_image {
            url
          }
          og_description {
            text
          }
        }
        canonical_url {
          text
        }
        icone_da_especialidade {
          url
        }
        nome_da_especialidade {
          richText
        }
        descricao_curta {
          richText
        }
        body {
          ... on PrismicEspecialidadeDataBodyBlocoDeTexto {
            slice_type
            id
            primary {
              titulo_do_bloco {
                richText
              }
              bloco_de_texto {
                richText
              }
            }
          }
          ... on PrismicEspecialidadeDataBodyTextoCom2Colunas {
            slice_type
            id
            primary {
              titulo_do_bloco {
                richText
              }
              texto_da_segunda_coluna {
                richText
              }
              texto_da_primeira_coluna {
                richText
              }
            }
          }
          ... on PrismicEspecialidadeDataBodySecaoDeExames {
            slice_type
            id
            items {
              seletor_de_exames {
                document {
                  ... on PrismicExames {
                    id
                    uid
                    data {
                      body {
                        ... on PrismicExamesDataBodyHero {
                          id
                          primary {
                            codigo_sollutio {
                              text
                            }
                            nome_do_exame {
                              text
                            }
                            codigo_tuss {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicEspecialidadeDataBodySeparador {
            slice_type
            separator: id
          }
        }
      }
    }
    prismicPaginaEspecialidades {
      uid
    }
    prismicBuscarExames {
      uid
    }
  }
`;

export default InternalSpecialtyPage;
